import { action, makeObservable, observable } from "mobx";
import Sound from "../type/Sound";
import soundService from "../service/sound.service";
import { RcFile } from "antd/lib/upload/interface";

export class SoundStore {
  sounds: Sound[] = [];

  fetchSounds() {
    soundService.get().then((sounds) => {
      this.sounds = sounds;
    });
  }

  refreshSound = (soundId: number) => {
    soundService.get(soundId).then((sound) => {
      const idx = this.sounds.findIndex(({ id }) => id === soundId);
      this.sounds = [
        ...this.sounds.slice(0, idx),
        sound,
        ...this.sounds.slice(idx + 1),
      ];
    });
  };

  constructor() {
    makeObservable(this, {
      sounds: observable,
      fetchSounds: action.bound,
      create: action.bound,
      update: action.bound,
      upload: action.bound,
      delete: action.bound,
    });
  }

  create(title: string) {
    soundService.post({ title }).then((data) => {
      this.sounds = [...this.sounds, data];
    });
  }

  update(soundId: number, title: string) {
    soundService.put({ title }, soundId).then((data) => {
      const idx = this.sounds.findIndex(({ id }) => id === soundId);
      this.sounds = [
        ...this.sounds.slice(0, idx),
        data,
        ...this.sounds.slice(idx + 1),
      ];
    });
  }

  upload(soundId: number, file: RcFile) {
    const formData = new FormData();
    formData.append("sound", file);
    soundService.post(undefined, `${soundId}/upload`, formData).then((data) => {
      const idx = this.sounds.findIndex(({ id }) => id === soundId);
      this.sounds = [
        ...this.sounds.slice(0, idx),
        data,
        ...this.sounds.slice(idx + 1),
      ];
    });
  }

  delete(soundId: number) {
    soundService.delete(soundId).then(() => {
      this.sounds = this.sounds.filter(({ id }) => id !== soundId);
    });
  }
}

const soundStore = new SoundStore();

export default soundStore;
