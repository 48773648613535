import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
import Input from "../../component/input";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import authStore from "../../store/auth.store";

const { Text } = Typography;

const Container = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
  & > div {
    width: 40vw;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

interface Props {}

const Login: React.FC<Props> = () => {
  const [token, setToken] = useState<string>("");
  let history = useHistory();

  const handleLogin = useCallback(async () => {
    authStore.setToken(token);
    const authorized = await authStore.checkAuth();
    if (authorized) {
      history.push("/");
    }
  }, [history, token]);

  if (authStore.authorized) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <div>
        <Text type="success">Please enter TOKEN</Text>
        <Input onChange={setToken} value={token} />
        <Button type="primary" onClick={handleLogin}>
          Enter
        </Button>
      </div>
    </Container>
  );
};

export default Login;
