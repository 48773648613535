import authStore from "../store/auth.store";

class BaseService {
  type?: string;
  apiUrl: string = "";

  constructor(type: string) {
    this.type = type;
    this.apiUrl = process.env.REACT_APP_API_URL ?? "";
  }

  getHeaders(noContentType: boolean = false): Record<string, string> {
    const headers: Record<string, string> = { token: authStore.token ?? "" };
    if (!noContentType) {
      headers["Content-Type"] = "application/json";
    }
    return headers;
  }

  request(
    method: string,
    url?: string | number,
    payload?: any,
    body?: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${this.apiUrl}/admin/${this.type}${url ? `/${url}` : ""}`, {
          method,
          headers: this.getHeaders(!!body),
          ...(payload || body ? { body: body ?? JSON.stringify(payload) } : {}),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              resolve(res.data);
            } else {
              reject("Something wrong");
            }
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  get(url?: string | number): Promise<any> {
    return this.request("get", url);
  }

  post(payload: any, url?: string | number, body?: any): Promise<any> {
    return this.request("POST", url, payload, body);
  }

  put(payload: any, url?: string | number): Promise<any> {
    return this.request("PUT", url, payload);
  }

  delete(url?: string | number): Promise<any> {
    return this.request("DELETE", url);
  }
}

export default BaseService;
