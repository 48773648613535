import Button from "antd/es/button";
import React, { useCallback } from "react";
import styled from "styled-components";
import { List } from "antd";
import { observer } from "mobx-react-lite";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import soundTagStore from "../../store/soundTag.store";
import SoundTag from "../../type/SoundTag";

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & > span {
    cursor: pointer;
  }
`;

interface Props {}

const SoundTags: React.FC<Props> = observer(() => {
  const { tags } = soundTagStore;

  const handleAddSound = useCallback(() => {
    const name = prompt("New tag name");
    if (name !== null) {
      if (name?.trim() !== "") {
        soundTagStore.create(name!);
      } else {
        alert("Empty name");
      }
    }
  }, []);

  const handleEditSound = useCallback((tag: SoundTag) => {
    const name = prompt("Edit tag name", tag.name ?? "");
    if (name !== null) {
      if (name?.trim() !== "") {
        soundTagStore.update(tag.id, name!);
      } else {
        alert("Empty name");
      }
    }
  }, []);

  const handleDeleteSound = useCallback((tag: SoundTag) => {
    if (window.confirm("Do you want to delete this sound tag?")) {
      soundTagStore.delete(tag.id);
    }
  }, []);

  return (
    <div>
      <div>
        <Button type="primary" onClick={handleAddSound}>
          Add tag
        </Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={tags}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<div>{item.name ?? <i>No Name</i>}</div>}
              description={
                <Actions>
                  <EditOutlined onClick={() => handleEditSound(item)} />
                  <DeleteOutlined onClick={() => handleDeleteSound(item)} />
                </Actions>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
});

export default SoundTags;
