import { observable, action, makeObservable } from "mobx";
import authService from "../service/auth.service";

export class AuthStore {
  storageTokenKey = "__TOKEN__";
  token?: string;
  authChecking: boolean = false;
  authorized: boolean = false;
  initialized: boolean = false;

  setToken(token: string) {
    localStorage.setItem(this.storageTokenKey, token);
    this.token = token;
  }

  logout() {
    localStorage.removeItem(this.storageTokenKey);
    this.token = undefined;
    this.authorized = false;
    this.checkAuth();
  }

  checkAuth(): Promise<boolean> {
    this.authChecking = true;
    if (this.token) {
      return authService
        .get()
        .then(() => {
          this.authorized = true;
          return Promise.resolve(true);
        })
        .catch(() => {
          this.authorized = false;
          this.token = undefined;
          return Promise.resolve(false);
        })
        .finally(() => {
          this.authChecking = false;
          this.initialized = true;
        });
    } else {
      this.initialized = true;
      this.authChecking = false;
    }
    return Promise.resolve(false);
  }

  constructor() {
    makeObservable(this, {
      token: observable,
      authChecking: observable,
      authorized: observable,
      initialized: observable,
      setToken: action.bound,
      logout: action.bound,
      checkAuth: action.bound,
    });
    this.token = localStorage.getItem(this.storageTokenKey) ?? undefined;
  }
}

const authStore = new AuthStore();

export default authStore;
