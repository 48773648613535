import { action, makeObservable, observable } from "mobx";
import SoundTag from "../type/SoundTag";
import soundTagService from "../service/soundTag.service";
import soundStore from "./sound.store";
import Sound2Tag from "../type/Sound2Tag";

export class SoundTagStore {
  tags: SoundTag[] = [];

  fetchSoundTags() {
    soundTagService.get().then((tags) => {
      this.tags = tags;
    });
  }

  refreshSoundTag = (tagId: number) => {
    soundTagService.get(tagId).then((tag) => {
      const idx = this.tags.findIndex(({ id }) => id === tagId);
      this.tags = [
        ...this.tags.slice(0, idx),
        tag,
        ...this.tags.slice(idx + 1),
      ];
    });
  };

  constructor() {
    makeObservable(this, {
      tags: observable,
      fetchSoundTags: action.bound,
      refreshSoundTag: action.bound,
      create: action.bound,
      update: action.bound,
      delete: action.bound,
    });
  }

  create(name: string) {
    soundTagService.post({ name }).then((data) => {
      this.tags = [...this.tags, data];
    });
  }

  update(tagId: number, name: string) {
    soundTagService.put({ name }, tagId).then((data) => {
      const idx = this.tags.findIndex(({ id }) => id === tagId);
      this.tags = [
        ...this.tags.slice(0, idx),
        data,
        ...this.tags.slice(idx + 1),
      ];
    });
  }

  delete(tagId: number) {
    soundTagService.delete(tagId).then(() => {
      this.tags = this.tags.filter(({ id }) => id !== tagId);
    });
  }

  addToSound(tagId: number, soundId: number) {
    soundTagService.post({ tagId, soundId }, "connect").then(() => {
      this.refreshSoundTag(tagId);
      soundStore.refreshSound(soundId);
    });
  }

  removeFromSound({ id, tagId, soundId }: Sound2Tag) {
    soundTagService.post(undefined, `disconnect/${id}`).then(() => {
      this.refreshSoundTag(tagId);
      soundStore.refreshSound(soundId);
    });
  }
}

const soundTagStore = new SoundTagStore();

export default soundTagStore;
