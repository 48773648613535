import Button from "antd/es/button";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { List, Select, Upload } from "antd";
import soundStore from "../../store/sound.store";
import { observer } from "mobx-react-lite";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import Sound from "../../type/Sound";
import { RcFile } from "antd/lib/upload/interface";
import soundTagStore from "../../store/soundTag.store";

const { Option } = Select;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & > span {
    cursor: pointer;
  }
`;

const TagSelect = styled(Select)`
  width: 350px;
`;

let audio = new Audio();

interface Props {}

const SoundList: React.FC<Props> = observer(() => {
  const { sounds } = soundStore;
  const { tags } = soundTagStore;

  const [playingSound, setPlayingSound] = useState<Sound | undefined>();

  const handlePlaySound = useCallback(
    (sound: Sound) => {
      audio.pause();
      if (playingSound?.source === sound.source) {
        setPlayingSound(undefined);
      } else {
        audio = new Audio(sound.source);
        audio.play();
        audio.onended = () => {
          setPlayingSound(undefined);
        };
        setPlayingSound(sound);
      }
    },
    [playingSound, setPlayingSound]
  );

  useEffect(() => {
    soundStore.fetchSounds();
  }, []);

  const handleAddSound = useCallback(() => {
    const title = prompt("New sound title");
    if (title !== null) {
      if (title?.trim() !== "") {
        soundStore.create(title!);
      } else {
        alert("Empty title");
      }
    }
  }, []);

  const handleEditSound = useCallback((sound: Sound) => {
    const title = prompt("Edit sound title", sound.title ?? "");
    if (title !== null) {
      if (title?.trim() !== "") {
        soundStore.update(sound.id, title!);
      } else {
        alert("Empty title");
      }
    }
  }, []);

  const handleDeleteSound = useCallback((sound: Sound) => {
    if (window.confirm("Do you want to delete this sound?")) {
      soundStore.delete(sound.id);
    }
  }, []);

  const handleUpload = useCallback((sound: Sound, file: RcFile) => {
    soundStore.upload(sound.id, file);
  }, []);

  const handleChangeTags = useCallback((sound: Sound, ids: number[]) => {
    const oldIds = sound.Sound2Tags.map(({ tagId }) => tagId);
    ids
      .filter((id) => !oldIds.includes(id))
      .map((id) => soundTagStore.addToSound(id, sound.id));
    sound.Sound2Tags.filter(({ tagId }) => !ids.includes(tagId)).map((record) =>
      soundTagStore.removeFromSound(record)
    );
  }, []);

  return (
    <div>
      <div>
        <Button type="primary" onClick={handleAddSound}>
          Add sound
        </Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={sounds}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<div>{item.title ?? <i>No Title</i>}</div>}
              description={
                <Actions>
                  <EditOutlined onClick={() => handleEditSound(item)} />
                  <Upload
                    fileList={[]}
                    beforeUpload={(file) => {
                      handleUpload(item, file);
                      return false;
                    }}
                  >
                    <CloudUploadOutlined />
                  </Upload>
                  <DeleteOutlined onClick={() => handleDeleteSound(item)} />
                  {item.source &&
                    (item.source === playingSound?.source ? (
                      <PauseCircleOutlined
                        onClick={() => handlePlaySound(item)}
                      />
                    ) : (
                      <PlayCircleOutlined
                        onClick={() => handlePlaySound(item)}
                      />
                    ))}
                  <TagSelect
                    mode="tags"
                    placeholder="Select tags"
                    onChange={(e) => handleChangeTags(item, e as number[])}
                    value={item.Sound2Tags.map(({ tagId }) => tagId)}
                  >
                    {tags.map((tag) => (
                      <Option key={tag.id} value={tag.id}>
                        {tag.name}
                      </Option>
                    ))}
                  </TagSelect>
                </Actions>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
});

export default SoundList;
