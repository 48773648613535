import Button from "antd/es/button";
import React, { useEffect } from "react";
import styled from "styled-components";
import authStore from "../../store/auth.store";
import { observer } from "mobx-react-lite";
import SoundList from "./Sounds";
import { Tabs } from "antd";
import SoundTags from "./SoundTags";
import soundTagStore from "../../store/soundTag.store";

const { TabPane } = Tabs;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
`;

const ListContainer = styled.div`
  padding: 1rem;
`;

const TopNav = styled.div`
  background-color: #2e87a2;
  border: 1px solid grey;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
  font-weight: bold;
`;

interface Props {}

const Home: React.FC<Props> = observer(() => {
  useEffect(() => {
    soundTagStore.fetchSoundTags();
  }, []);

  return (
    <Container>
      <TopNav>
        <div>ADMINKA</div>
        <Button onClick={authStore.logout}>Logout</Button>
      </TopNav>
      <ListContainer>
        <Tabs tabPosition="left">
          <TabPane tab="Sounds" key="1">
            <SoundList />
          </TabPane>
          <TabPane tab="Sound tags" key="2">
            <SoundTags />
          </TabPane>
        </Tabs>
      </ListContainer>
    </Container>
  );
});

export default Home;
