import React, { useEffect } from "react";
import authStore from "../store/auth.store";
import { observer } from "mobx-react-lite";
import "antd/dist/antd.css";
import Loader from "../component/loader";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Login from "./login";
import Home from "./home";
import { RouteProps } from "react-router";

interface SecureRouteProps extends RouteProps {
  isAuth: boolean;
}

const SecureRoute: React.FC<SecureRouteProps> = ({
  children,
  isAuth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() => (isAuth ? children : <Redirect to="/login" />)}
    />
  );
};

const App = observer(() => {
  const { authChecking, checkAuth, authorized, initialized } = authStore;

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authChecking || !initialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <SecureRoute path="/" isAuth={authorized}>
        <Home />
      </SecureRoute>
    </Switch>
  );
});

export default withRouter(App);
