import React, { ChangeEvent, useCallback } from "react";
import AntdInput, { InputProps } from "antd/es/input/Input";

interface Props extends Omit<InputProps, "onChange"> {
  onChange: (value: string) => void;
}

const Input: React.FC<Props> = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },

    [onChange]
  );
  return <AntdInput onChange={handleChange} {...props} />;
};

export default Input;
